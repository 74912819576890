// pull Development

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import search from './modules/search.js'

//	const mainUrl = "http://localhost:8080/admin000"
const mainUrl = "https://bibliotecha.pavladev.gr/admin000"
const mainUrl2 = "http://bibliotecha.pavladev.gr/admin000"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		rootUrl: `${mainUrl}/api/`,
		imgUrl: `${mainUrl2}`,
		limit: 20,
		apiPage: 1,
		authors: [],
		categories: [],
		queryTitle: '',
		queryDescription: '',
		queryCode: null,
		queryCat: null,
		queryCatName: null,
		queryAuthID: null,
		wDateStart: null,
		wDateEnd: null,
		pDateStart: null,
		pDateEnd: null,
		loading: false,
		queryAuthName: null,
		cities: [],
		queryCityID: null,
		queryCityName: null,
		queryBirthPlaceCityID: null,
		queryBirthPlaceName: null
	},
	actions: {
		loadAuthors({ commit }, state) {
			this.state.loading = true
			axios.get(`${this.state.rootUrl}authors`)
				.then((response) => {
					this.state.loadind = false
					commit('updateAuthors', response.data.data)
				})
		},
		loadCities({ commit }, state) {
			this.state.loading = true
			axios.get(`${this.state.rootUrl}cities`)
				.then((response) => {
					this.state.loadind = false
					commit('updateCities', response.data.data)
				})
		},
		loadCategories({ commit }, state) {
			this.state.loading = true
			axios.get(`${this.state.rootUrl}categories`)
				.then((response) => {
					this.state.loading = false
					commit('updateCategories', response.data.data)
				})
		},
		requestCat({ commit }, payload) {
			commit('filterCategories', payload)
		},
		requestCatName({ commit }, payload) {
			commit('updateCatName', payload)
		},
		requestAuthID({ commit }, payload) {
			commit('filterWriters', payload)
		},
		requestCityID({ commit }, payload) {
			commit('filterCityId', payload)
		},
		requestBirthPlaceCityID({ commit }, payload) {
			commit('filterBirthPlaceCityID', payload)
		},
		requestAuthName({ commit }, payload) {
			commit('filterAuthors', payload)
		},
		requestCityName({ commit }, payload) {
			commit('filterCityName', payload)
		},
		requestBirthPlaceName({ commit }, payload) {
			commit('filterBirthPlaceName', payload)
		},
		requestTitle({ commit }, payload) {
			commit('updateTitle', payload)
		},
		requestDescription({ commit }, payload) {
			commit('updateDescription', payload)
		},
		requestCode({ commit }, payload) {
			commit('updateCode', payload)
		},
		requestwDateStart({ commit }, payload) {
			commit('updatewDateStart', payload)
		},
		requestwDateEnd({ commit }, payload) {
			commit('updatewDateEnd', payload)
		},
		requestpDateStart({ commit }, payload) {
			commit('updatepDateStart', payload)
		},
		requestpDateEnd({ commit }, payload) {
			commit('updatepDateEnd', payload)
		},
		prevApiPage({ commit, dispatch }) {
			commit('getPrevApiPage')
			dispatch('searchData', search.state.queryUrl)
		},
		nextApiPage({ commit, dispatch }) {
			commit('getNextApiPage')
			dispatch('searchData', search.state.queryUrl)
		},
		resetApiPage({ commit }) {
			commit('resetApiPagination')
		},
		resetData({ commit }) {
			commit('clearSearch')
		},
		setqueryAuthName({ commit }, payload) {
			commit('updatequeryAuthName', payload)
		},
		setqueryCityName({ commit }, payload) {
			commit('updatequeryCityName', payload)
		},
		setqueryBirthPlaceName({ commit }, payload) {
			commit('updatequeryBirthPlaceName', payload)
		},
		setqueryCatName({ commit }, payload) {
			commit('updatequeryCatName', payload)
		}
	},
	mutations: {
		updateTitle(state, newTitle) {
			state.queryTitle = newTitle
			// console.log("title:", state.queryTitle)
		},
		updateDescription(state, newDscr) {
			state.queryDescription = newDscr
			// console.log("title:", state.queryDescription)
		},
		updateCode(state, newCode) {
			state.queryCode = newCode
			// console.log("title:", state.queryDescription)
		},
		updateAuthors(state, newAuthors) {
			state.authors = newAuthors
		},
		updateCities(state, newCities) {
			state.cities = newCities
		},
		updateCategories(state, newCategories) {
			state.categories = newCategories
		},
		filterCategories(state, catId) {
			state.queryCat = catId
			// console.log("category:", 	state.queryCat)
		},
		updateCatName(state, newName) {
			state.queryCatName = newName
		},
		filterAuthors(state, authName) {
			state.queryAuth = authName
			// console.log("author:", state.queryAuth)
		},
		filterCityName(state, cityName) {
			state.queryCityName = cityName
		},
		filterBirthPlaceName(state, cityName) {
			state.queryBirthPlaceName = cityName
		},
		filterWriters(state, authid) {
			state.queryAuthID = authid
		},
		filterCityId(state, cityid) {
			state.queryCityID = cityid
		},
		filterBirthPlaceCityID(state, cityid) {
			state.queryBirthPlaceCityID = cityid
		},
		updatewDateStart(state, newDate) {
			state.wDateStart = newDate
		},
		updatewDateEnd(state, newDate) {
			state.wDateEnd = newDate
		},
		updatepDateStart(state, newDate) {
			state.pDateStart = newDate
		},
		updatepDateEnd(state, newDate) {
			state.pDateEnd = newDate
		},
		getPrevApiPage(state) {
			state.apiPage--
		},
		getNextApiPage(state) {
			state.apiPage++
		},
		resetApiPagination(state) {
			state.apiPage = 1
		},
		updatequeryAuthName(state, newName) {
			state.queryAuthName = newName
		},
		updatequeryCityName(state, newName) {
			state.queryCityName = newName
		},
		updatequeryBirthPlaceName(state, newName) {
			state.queryBirthPlaceName = newName
		},
		updatequeryCatName(state, newName) {
			state.queryCatName = newName
		},
		clearSearch(state) {
			state.queryTitle = ''
			state.queryCat = null
			state.queryDescription = null
			state.queryCode = null
			state.queryCatName = null
			state.queryAuthID = null
			state.wDateStart = null
			state.wDateEnd = null
			state.pDateStart = null
			state.pDateEnd = null
			state.queryAuthName = null
			state.queryCatName = null
			state.queryCityName = null
			state.queryCityID = null
			state.queryBirthPlaceName = null
			state.queryBirthPlaceCityID = null
		}
	},
	getters: {
		getUrl(state) {
			return state.rootUrl
		},
		getAuthors(state) {
			return state.authors
		},
		getCatNames(state) {
			return Array.from(state.categories, ({ DescriptionEL }) => DescriptionEL)
		},
		getAuthNames(state) {
			return Array.from(state.authors, ({ CreatorNameEL, CreatorNameEN }) => `${CreatorNameEL} - ${CreatorNameEN}`)
		},
		getCityNames(state) {
			return Array.from(state.cities, ({ CityNameEL, CityNameEN }) => `${CityNameEL} - ${CityNameEN}`)
		},
		getBirthPlaceCity(state) {
			return Array.from(state.cities, ({ CityNameEL, CityNameEN }) => `${CityNameEL} - ${CityNameEN}`)
		},
		getReqTitle(state) {
			return state.queryTitle
		},
		getReqDescription(state) {
			return state.queryDescription
		},
		getReqCode(state) {
			return state.queryCode
		},
		getReqAuthID(state) {
			return state.queryAuthID
		},
		getReqCityID(state) {
			return state.queryCityID
		},
		getReqBirthPlaceCityID(state) {
			return state.queryBirthPlaceCityID
		},
		getReqCat(state) {
			return state.queryCat
		},
		getwDateStart(state) {
			return state.wDateStart
		},
		getwDateEnd(state) {
			return state.wDateEnd
		},
		getpDateStart(state) {
			return state.pDateStart
		},
		getpDateEnd(state) {
			return state.pDateEnd
		},
		getqueryAuthName(state) {
			return state.queryAuthName
		},
		getqueryCityName(state) {
			return state.queryCityName
		},

		getqueryBirthPlaceName(state) {
			return state.queryBirthPlaceName
		},
		getqueryCatName(state) {
			return state.queryCatName
		}
	},
	modules: {
		search
	}
})
