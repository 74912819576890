<template>
	<div class="filters">

		<div class="middle-row">

			<div class="field-container md-elevation-4">
				<md-field @md-clear="searchCode=null" md-clearable class="prop-query">
					<label>ΚΩΔΙΚΟΣ</label>
					<md-input v-model="$store.state.queryCode"></md-input>
				</md-field>
			</div>

			<div	class="field-container md-elevation-4">
				<bib-autocomplete
					bright
					:pl-holder="searchCategory"
					ref="ac1"
					:items="getCatNames"
					:label="'ΚΑΤΗΓΟΡΙΑ'"
					@cleared="clearCategory"
					@selectionMade="setCategory"
				></bib-autocomplete>
			</div>

			<div class="field-container md-elevation-4">
				<bib-autocomplete
					bright
					ref="ac2"
					:items="getAuthNames"
					:label="'ΣΥΓΓΡΑΦΕΑΣ'"
					:pl-holder="searchAuthor"
					@input="getAuthInput"
					@cleared="clearAuthor"
					@selectionMade="setAuthor"
					@ready="checkForExistingValues"
				></bib-autocomplete>
			</div>
		</div>
		<div class="top-row">
			<div class="field-container md-elevation-4">
				<md-field @md-clear="searchTitle=null" md-clearable class="prop-query">
					<label>ΣΥΝΟΠΤΙΚΟΣ ΤΙΤΛΟΣ</label>
					<md-input v-model="$store.state.queryTitle"></md-input>
				</md-field>
			</div>

			<div class="field-container md-elevation-4">
				<md-field @md-clear="searchDescription=null" md-clearable class="prop-query">
					<label>ΠΕΡΙΓΡΑΦΗ</label>
					<md-input v-model="$store.state.queryDescription"></md-input>
				</md-field>
			</div>
		</div>

		<div class="bottom-row">
			<div class="field-container md-elevation-4">
				<h4>
					<img width="20px" src="@/assets/img/written.png" alt="">
					ΧΡ/ΓΙΑ ΣΥΓΓΡΑΦΗΣ:<br><span class="text-tooltip">(eg: -800 to -600)</span></h4>

				<div class="queries">
					<md-field class="prop-query">
						<md-input maxlength="5" v-model.number="w_DateStart" type="number" placeholder="από"></md-input>
					</md-field>
					<md-field class="prop-query">
						<md-input maxlength="5" v-model.number="w_DateEnd" type="number" placeholder="έως"></md-input>
					</md-field>
				</div>
			</div>
			<div class="field-container md-elevation-4">
				<h4>
					<img width="20px" src="@/assets/img/published.png" alt="">
						ΧΡ/ΓΙΑ ΕΚΔΟΣΗΣ:</h4>
				<div class="queries">
					<md-field class="prop-query">
						<md-input maxlength="5" v-model.number="p_DateStart" type="number" placeholder="από"></md-input>
					</md-field>
					<md-field class="prop-query">
						<md-input maxlength="5" v-model.number="p_DateEnd" type="number" placeholder="έως"></md-input>
					</md-field>
				</div>
			</div>
		</div>
		<div class="bottom-row ac-filter">
			<div class="field-container md-elevation-4">
					<bib-autocomplete
							bright
							ref="ac3"
							:items="getCityNames"
							:label="'ΤΟΠΟΣ ΣΥΓΓΡΑΦΗΣ'"
							:pl-holder="searchCity"
							@input="getCityInput"
							@cleared="clearCity"
							@selectionMade="setCity"
							@ready="checkForExistingValues"
					></bib-autocomplete>
			</div>
			<div class="field-container md-elevation-4">
				<div class="queries">
					<bib-autocomplete
							bright
							ref="ac4"
							:items="getBirthPlaceCity"
							:label="'ΤΟΠΟΣ ΓΕΝΝΗΣΗΣ'"
							:pl-holder="searchBirthPlaceName"
							@input="getBirthPlaceInput"
							@cleared="clearBirthPlace"
							@selectionMade="setBirthPlaceCity"
							@ready="checkForExistingValues"
					></bib-autocomplete>
					<br>
					<md-field class="prop-query">
					</md-field>
				</div>
			</div>
		</div>
		<div class="refresh-container">
			<md-button @click="clearAll()" class="md-icon-button"> <img width="25px" style="margin-left: 0px; background-color: transparent;" src="@/assets/img/clear.png" alt=""> </md-button>
		</div>
		<div class="button-container centering">
			<md-button v-scroll-to="'#resultStart'" @click="getResults()" class="md-raised md-icon-button go-btn"> <img width="15px" style="margin-left: 7px;" src="@/assets/img/play.png" alt=""> </md-button>
		</div>
	</div>
</template>

<script>
import { searchMixin } from '@/mixins/searchMixin'
import BibAutocomplete from '@/components/ui/BibAutocomplete.vue'

export default {
	name: "Filters",
	mixins: [searchMixin],
	components: {
		BibAutocomplete
	},
	data: () => ({
		// selectedCat: null
	}),
	created() {
		// this.setCategory()
	},
	mounted() {
		// console.log(this.$store.state.queryCatName)
		// this.searchTitle = this.$store.state.queryTitle
		// this.selectedCat = this.searchCategory
	}
	// watch: {
	// 	hasInputValue(x, y) {
	// 		console("hello")
	// 	}
	// }
}
</script>

<style lang="scss" scoped>

	.filters {
		display: flex;
    flex-direction: column;
		flex-flow: wrap;
		background-color: transparent;

		label {
			font-size: calc(11px + (18 - 11) * ((100vw - 300px) / (1600 - 300)))
		}

		h4 {
			white-space: nowrap;
		}
	}

	.filters > div:not(:last-child) {
		width: 93%;
		justify-content: space-between;
		display: flex;
		flex-direction: row;
	}

	.top-row	{
		margin-top: 2%;

		.field-container {
			width: 48.5%;
			background-color: #fff;
			border-radius: 7px;
			padding: 0 2%;
		}

		.prop-query {

			.md-field .md-theme-default:before {
				border-radius: 7px;
				width: 90%;
			}

			label {
				color: $grey;
			}

			input {
				padding: 0%;
				-webkit-text-fill-color: #000!important;
			}
		}
	}

	.middle-row{
		margin-top: 0%;

		.field-container {
			width: 32.5%;
			border-radius: 7px;
			padding: 0 2%;
			background-color: #fff;
		}

		.prop-query {

			.md-field .md-theme-default:before {
				border-radius: 7px;
				width: 90%;
			}

			label {
				color: $grey;
			}

			input {
				padding: 0%;
				-webkit-text-fill-color: #000!important;
			}
		}
	}

	.ac-filter .md-field.md-theme-default:before, .ac-filter .md-field.md-theme-default:after{
			background-color:unset !important;
		}

	.bottom-row {
		margin-top: 2%;

			.md-field.md-theme-default:before,
			.md-field.md-theme-default:after {
				background-color: $grey;
			}

		.field-container {
			display: flex;
			width: 48.5%;
			border-radius: 7px;
			background-color: #fff;
			padding: 0 2%;
			justify-content: space-between;

			h4 {
				font-size: calc(11px + (15 - 11) * ((100vw - 300px) / (1600 - 300)));
				align-self: center;
				font-weight: 100;
				text-align: left;
				margin-right: 21px;
				color: $grey;
			}

			.queries {
				display: flex;
				justify-content: space-between;
				flex: 1;
			}

			.prop-query {
				width: 45%;
			}

			input {
				padding: 0%;
				-webkit-text-fill-color: #000!important;
				margin-right: 5px;
				min-width: 45px;
				width: 5%;
			}
		}
	}

	.refresh-container {
		justify-content: flex-end!important;
		padding-top: 10px;
	}

	.button-container {
			margin-left: 1%;
			margin-top: -19%;

		.go-btn {
			background-color: #fff!important;
			padding: 1%;
		}
	}

@media(max-width: 1550px) {
		.button-container {
			margin-top: -25%;
		}
}

@media(max-width: 1350px) {
	.filters	h4 {
		white-space: normal;
		width: 35%;
	}

	.filters > div:not(:last-child) {
		width: 90%;
	}

	.bottom-row .field-container input {
    min-width: 35px;
	}

		.button-container {
			margin-left: 1%;
			margin-top: -28%;
		}
}

	@media(max-width: 1100px) {

		.filters > div:not(:last-child) {
			width: 93%;
		}

	.refresh-container {
		align-items: flex-end;
	}

		.button-container {
			margin-left: 0;
			margin-top: 0;
		}

			.filters {
				justify-content: center;
			}
			.filters > div:not(:last-child) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;

			.field-container {
				width: 100%;
				margin: 2% 0;
			}
		}

		.top-row, .bottom-row {
			margin-top: 0;
		}

		h4 {
			white-space: normal;
		}
	}

	@media (max-width: 560px) {
		.filters > div:not(:last-child) {
			width: 100%;
		}

		.go-btn {
			margin: 12% 0;
		}

	}
	.text-tooltip {
		font-size: 10px;
    margin-left: 1.5rem;
		color:red;
	}
</style>
