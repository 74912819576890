<template>
	<transition name="fadeIn" appear>
		<div @click="$emit('selected')" class="single-card">
			<md-card style="padding: 4%; border-radius: 12px;" class="inner-card" md-with-hover>
				<div class="title-container">
					<div v-if="hasThumb" class="img-container">
						<img :src="imgSrc" alt="">
						<!-- <div class="inner"></div> -->
					</div>
					<div :class="{'full-width-title' : !hasThumb }" class="title-text centering">
						<!-- <h3>{{ title }}</h3> -->
						<span v-if="isPrimary" class="prm-book">Primary</span>
						<h3 class="centering" v-if="title.length < 60">{{ title }}
						</h3>
						<h3 class="centering" v-else>{{ title.substring(0,45)+ "..." }}</h3>
					</div>
				</div>
				<hr>
				<div class="details">
					<div v-if="author" class="author">
						<span class="label">Συγγραφέας: </span>
						<span> {{ author }} </span>
					</div>
					<div class="info" v-if="publisher">
						<span class="label">Εκδότης: </span>
						<div class="info-content">
							<span> {{ publisher }} </span>,
							<span style="width: 3px;"> </span>
							<span v-if="pDate"> {{ p_dateFormat }}</span>
						</div>
					</div>

					<div class="info category" v-if="category">
						<span class="label">Κατηγορία Έργου: </span>
						<div class="info-content">
							<span> {{ category }} </span>
						</div>
					</div>

					<div class="info" v-if="code">
						<span class="label-code">Κωδικός Βιβλίου: </span>
						<div class="info-content">
							<span> {{ code }} </span>
						</div>
					</div>

				</div>
			</md-card>
		</div>
	</transition>
</template>

<script>
export default {
	name: "SingleCard",
	props: {
		title: {
			type: String,
			required: true,
			default: "Title not found"
		},
		author: {
			type: String,
			required: true,
			default: "Author not found"
		},
		category: {
			required: true,
			default: "Category not found"
		},
		publisher: {
			type: String,
			required: false,
			default: '-'
		},
		pDate: {
			type: String,
			required: false,
			default: '-'
		},
		code: {
			type: String,
			required: false,
			default: '-'
		},
		imgSrc: {
			type: String,
			required: false,
			default: '-'
		},
		isPrimary: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => {
		return {
		}
	},
	computed: {
		w_dateFormat() {
			// if (this.wDate.includes('-')) {
			// 	return this.wDate.replace("-", "") + "π.Χ."
			// }
			return this.wDate
		},
		p_dateFormat() {
			// if (this.pDate.includes('-')) {
			// 	return this.pDate.replace("-", "") + "π.Χ."
			// }
			// return this.pDate + "μ.Χ."
			return this.pDate
		},
		hasThumb() {
			if	(this.imgSrc) {
				return this.imgSrc.length
			}
			return false
		}
	}
}
</script>

<style lang="scss" scoped>

.single-card {
	background-color: transparent	;
	margin: 1rem;
	display: flex;
	flex-direction: column;
	border-radius: 24px;

	.inner-card {
		.prm-book {
			color: #fff;
			position: absolute;
			float: right;
			margin-left: auto;
			font-weight: bold;
			right: 7px;
			top: 9px;
			background-color: #b15e27 ;
			border-radius: 13px 2px 13px;
			padding: 2px 5px;
			font-size: 0.7rem;
		}
	}

	.title-container {
		padding-top: 12px;
		display: grid;
		grid-template-columns: 20% 80%;
		grid-template-rows: 80px;
		grid-template-rows: minmax(75px, 1fr);
		overflow: hidden;

		.img-container {
			width: 100%;
			display: flex;
			grid-column: 1;
			padding: 15% 15% 15% 0%;
			flex-direction: column;
			justify-content: center;

			img {
				max-height: 90%;
			}

		}

		.title-text {
			grid-column: 2;
			align-items: flex-start;
			justify-content: center;
			padding: 0;

			h3 {
				padding-left: 10px;
				text-align: left;
				text-align: left;
				font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
				font-weight: bold;
				color: $title-black;
				line-height: 1.2;
			}
		}
	}

	.full-width-title {
		grid-column: 1/3!important;
	}

	hr {
		color: $beige;
	}

	.details {
		width: 100%;
    // display: block;
		// flex-wrap: wrap;
		display: flex;
		flex-direction: column;
		line-height: 1.8;

		.label {
			font-weight: bold;
		}

		.author {
			width: 100%;
			display: flex;
			flex-wrap: wrap;

			&:first-child {
				flex-wrap: nowrap;

				span:not(.label) {
					width: 60%;
					margin-left: 5px;
					display: flex;
				}
			}

			&:nth-child(2) span:not(.label){
				margin-left: 5px;
			}
		}

		.info {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.label {
				padding-right: 4px;
			}

			.label-code {
				font-weight: bold;
				max-width: 95%;
				margin-right: 3px;
			}

			.info-content {
				flex: 1 1 auto;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-start;

				span {
					line-height: 1.4;
					padding-top: 3px
				}
			}

		}
	}
}

@media screen and (min-width: 600px) {
  .single-card {
		flex: 1 1 calc(50% - 2rem);
		max-width: 50%;
		min-height: 125px;
  }
}

@media screen and (min-width: 900px) {
  .single-card {
    flex: 1 1 calc(30% - 2rem);
		max-width: 30%;
		// max-width: 30%;
		min-width: 210px;
  }
}

@media screen and (max-width: 1024px) {
	.single-card .title-container .title-text {
		padding: 0 0% 0% 5%;
	}

	.single-card .details .info {
		// width: 45%;
	}

	.details .author:first-child {
		display: flex;
		// flex-direction: column;
	}
}

@media screen and (max-width: 900px) {

	.single-card .details .author:first-child span:not(.label) {
		margin-top: 5px;
		margin-bottom: 5px;
		line-height: 1.2;
	}

	.single-card .details .info .label {
	}

	.single-card .details .info .label-code {

	}
}

@media(max-width: 720px) {
	.single-card {
		width: 100%;
	}
}

@media(max-width: 480px) {
	.single-card {
	}
}

</style>
