<template>
	<div class="reuslts-page">
		<!-- <div class="banner-container">
			<div class="inner"></div>
		</div> -->
		<div class="results-container">
			<div class="filters-container">
				<Filters v-if="!isMobile" />
					<button
						v-if="isMobile"
						class="btn filter-btn btn-primary"
						@click="showFilters = !showFilters">
						<img v-if="showFilters" src="@/assets/img/filter_on.svg" alt="">
						<img v-if="!showFilters" src="@/assets/img/filter_off.svg" alt="">
					</button>
					<transition name="slide" type="transition">
						<mobile-filters v-if="isMobile && showFilters"></mobile-filters>
					</transition>

			</div>
			<hr>
			<div class="results centering">
				<h3 id="resultStart">Αποτελέσματα
					<span v-if="totalResults">
						({{ totalResults }})
					</span>
				</h3>
				<div
					v-if="bookResults.length > 0"
					class="cards-container">
					<SingleCard
						v-for="book in bookResults"
						:key="book.id"
						:img-src="getThumb(book.thumb_filename)"
						:title="book.ItemTitle"
						:author="book.CreatorNameEL"
						:category="book.categoryEl"
						:code="book.ItemCode"
						:publisher="concatNames(book.OwnerNameEL, '')"
						:p-date="book.CreationDate"
						:is-primary="book.is_primary == 1"
						@selected="goToBook(book.ItemID)"
					/>
					<!-- book.OwnerNameEN -->
				</div>
				<div v-else class="cards-container">
						<h3 class="no-results">Δεν βρέθηκαν Αποτελέσματα</h3>
				</div>
			</div>
		</div>
		<div v-if="bookResults.length > 0" class="pagination centering">
			<div class="controls">
				<span
					@click="getPrev"
					v-scroll-to="'#resultStart'"
					:class="{inactive: 	currentApiPage===1}"
					class="next">
					&lt;
				</span>
				<h4> {{ currentApiPage }} / {{ totalPages }} </h4>
				<span
					@click="getNext"
					v-scroll-to="'#resultStart'"
					:class="{inactive: 	currentApiPage===totalPages}"
					class="prev"
				>	 &gt;</span>
			</div>
		</div>
		<div class="books">
			<div class="inner"></div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex"
import Filters from "@/components/Filters.vue"
import MobileFilters from "@/components/ui/MobileFilters.vue"
import SingleCard from "@/components/SingleCard.vue"

export default {
	name: 'SearchResults',
	components: {
		Filters,
		MobileFilters,
		SingleCard
	},
	data: () => {
		return {
			myArray: [1, 2, 3, 4],
			isMobile: window.innerWidth <= 1100,
			showFilters: false
		}
	},
	created() {
		addEventListener('resize', () => {
			this.isMobile = window.innerWidth <= 1100
		})
	},
	computed: {
		bookResults() {
			return this.$store.state.search.search_books
		},
		totalResults() {
			return this.$store.state.search.result_count
		},
		currentApiPage() {
			return this.$store.state.apiPage
		},
		totalPages() {
			return this.$store.state.search.result_pages
		}
	},
	methods: {
		...mapActions(['searchData']),
		getPrev() {
			if (this.currentApiPage > 1) {
				this.$store.dispatch('prevApiPage')
			}
		},
		getNext() {
			if (this.currentApiPage < this.totalPages) {
				this.$store.dispatch('nextApiPage')
			}
		},
		goToBook(id) {
			this.$router.push({ path: '/book-profile/' + id })
		},
		concatNames(el, en) {
			let name = this.clearData(`${el} - ${en}`)
			return name
		},
		clearData(info) {
			if (info.startsWith("null - ")) {
				info = info.replace("null - ", "")
			}
			if (info.endsWith(" - null")) {
				info = info.replace(" - null", "")
			}
			return info
		},
		getThumb(path) {
			if (path) {
				return `${this.$store.state.imgUrl}${path}`
			}
			return ''
		}
	},
	mounted() {
		this.$nextTick(() => {
			// console.log(
			// 	this.bookResults
			// )
			// this.$scrollTo('#resultStart', 1000)
		})
	},
	watch: {
		currentApiPage(x, y) {
			// console.log("changed")
		}
	}
}
</script>

<style scoped lang="scss">
	.fadeIn-enter-active {
		opacity: 1;
		transition: all 1s ease;
	}

	.fadeIn-enter {
		opacity: 0;
	}

	.reuslts-page {
		background-image: url('../assets/img/2.jpg');
		background-size: cover;
		background-repeat: no-repeat;
	}

	.banner-container {
		width: 100%;
		height: 20vw;

		.inner {
			background-image: url('../assets/img/photo_search.jpg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			height: 100%;
			width: 100%;
		}
	}

	.mobile-filters {
		display: block;
		width: 100%;
	}

	.results-container {
		width: 100%;
		padding: 4% 10%;
		// background-color: $beige;
	}

	.results {
		text-align: left;
		max-width: 1900px;

		h3 {
			margin: 3% 0;
			font-size: calc(14px + (25 - 14) * ((100vw - 300px) / (1600 - 300)));
			color: rgba(0, 0, 0, 0.85)
		}
	}

	.filters-container {
		margin-bottom: 5%;
	}

	.cards-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		min-height: 80vh;
		width: 100%;

		> div {
			border-radius: 12px;
		}
	}

	.pagination {
		width: 100%;
		background-color: transparent;

		.page {
			list-style: none;
			display: inline;
		}

		.controls {
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;

			span {
				background-color: $orange;
				border-radius: 12px;
				width: 20px;
				height: 20px;
				color: $beige;
				cursor: pointer;
			}
			.inactive {
				background-color: $grey;
			}
		}

		h4 {
			margin: 0 2%;
			font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
		}
	}

	.books {
		width: 100%;
		height: 20vw;

		.inner {
			background-image: url('../assets/img/photo_books_1.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center bottom;
			background-color: transparent;
			width: 100%;
			padding: 10% 0;
		}
	}

	h3.no-results {
		color: $grey;
	}

	@media(max-width: 1200px) {
		.cards-container {
			justify-content: center;
		}
	}

	@media(max-width: 820px) {
		.reuslts-page {
			background-size: contain;
			background-repeat: repeat;
		}

		.results-container {
			padding: 4% 5%;
		}
	}

	@media(max-width: 720px) {
		.results-container {
			justify-content: center;
		}
	}

	.filter-btn {
		background: transparent;
    border: none;
	}

	@media(max-width: 560px) {
		.results-container {
			padding: 4% 5%;
		}
	}

	.filter-container {
		overflow: hidden;
	}

	.slide-enter {
		opacity: 0;
	}

	.slide-enter-active {
		// transition: transform 1s, opacity 1s;
		animation: slide-in 0.5s ease-out forwards;
		transition: all 0.5s;
		opacity: 1;
	}

	.slide-leave {
		opacity: 0;
	}

	.slide-leave-active {
		animation: slide-out 0.5s ease-out forwards;
		transition: all 0.5s;
	}

	@keyframes slide-in {
		from {
			transform: translateY(-50%);
		}

		to {
			transform: translateY(0%);
		}
	}

	@keyframes slide-out {
		from {
			transform: translateY(0%);
		}

		to {
			transform: translateY(-90%);
		}
	}
</style>
