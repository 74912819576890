<template>
	<div class="tabs-container">
	<vue-tabs>
    <v-tab title="Περιγραφή">
      <p @click.prevent="goToBook($event.target.dataset.bookid)" v-html="description"></p>
			<hr v-if="descriptionEN" />
			<p v-if="descriptionEN" @click.prevent="goToBook($event.target.dataset.bookid)" v-html="descriptionEN"></p>
    </v-tab>

    <v-tab title="Wikipedia" v-if="wiki">
			<p @click.prevent="goToBook($event.target.dataset.bookid)" v-html="wiki"></p>
    </v-tab>

    <v-tab title="Λεξικό ΣΟΥΔΑΣ" v-if="sou">
      <p @click.prevent="goToBook($event.target.dataset.bookid)" v-html="sou"></p>
    </v-tab>
	</vue-tabs>
	</div>
</template>

<script>
import { VueTabs, VTab } from 'vue-nav-tabs'
// you can also import this in your style tag
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
	name: "BibTabs",

	components: {
		VueTabs,
		VTab
	},
	props: {
		description: String,
		descriptionEN: String,
		wiki: String,
		sou: String,
		dl: String
	},
	methods: {
		goToBook(e) {
			// console.log(e)
			if (e) {
				let url = `${this.$store.state.rootUrl}mainbook/${e}`
				// console.log(url)
				this.$http.get(url)
					.then((res) => {
						const bookId = res.data.data
						// console.log(bookId)
						this.$router.push({ path: `/book-profile/${bookId}` })
					})
			}
		}
	}
}
</script>

<style lang="scss" scoped>

p {
	font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
	line-height: 1.4;
}

.spacer {
	height: 100%;
	padding: 10% 1%;
	background-color: #000;
	width: 5px;
}

.nav-tabs {
	list-style: none;
}

@media (max-width: 1200px) {
	.tabs-container {
		width: 100%;
	}
}
</style>
