import { mapGetters, mapActions } from 'vuex'
export const searchMixin = {
	data: () => ({
		searchAuthor: null,
		searchCategory: null,
		searchCatName: '',
		searchCity: null,
		searchBirthPlaceName: null,
		categories: [],
		authors: [],
		cities: [],
		birthPlacecities: []
	}),
	beforeCreate() {
		this.$store.dispatch('loadCategories')
		this.$store.dispatch('loadAuthors')
		this.$store.dispatch('loadCities')
	},
	computed: {
		...mapGetters([
			'getCatNames',
			'getAuthNames',
			'getReqTitle',
			'getReqAuth',
			'getReqCat',
			'getwDateStart',
			'getwDateEnd',
			'getpDateStart',
			'getpDateEnd',
			'getCityNames',
			'getBirthPlaceCity'
		]),
		searchTitle: {
			get() {
				return this.$store.getters.queryTitle
			},
			set(value) {
				this.$store.dispatch('requestTitle', value)
			}
		},
		searchDescription: {
			get() {
				return this.$store.getters.queryDescription
			},
			set(value) {
				this.$store.dispatch('requestDescription', value)
			}
		},
		searchCode: {
			get() {
				return this.$store.getters.queryCode
			},
			set(value) {
				this.$store.dispatch('requestCode', value)
			}
		},
		w_DateStart: {
			get() {
				return this.$store.getters.getwDateStart
			},
			set(value) {
				this.$store.dispatch('requestwDateStart', value)
			}
		},
		w_DateEnd: {
			get() {
				return this.$store.getters.getwDateEnd
			},
			set(value) {
				this.$store.dispatch('requestwDateEnd', value)
			}
		},
		p_DateStart: {
			get() {
				return this.$store.getters.getpDateStart
			},
			set(value) {
				this.$store.dispatch('requestpDateStart', value)
			}
		},
		p_DateEnd: {
			get() {
				return this.$store.getters.getpDateEnd
			},
			set(value) {
				this.$store.dispatch('requestpDateEnd', value)
			}
		}
	},
	methods: {
		...mapActions([
			'requestTitle',
			'requestDescription',
			'requestCat',
			'requestCatName',
			'requestAuthID',
			'requestAuthName',
			'requestwDateStart',
			'requestwDateEnd',
			'requestpDateStart',
			'requestpDateEnd',
			'searchData',
			'resetApiPage',
			'setqueryAuthName',
			'setqueryCatName',
			'resetData',
			'setqueryCityName',
			'requestCityID',
			'requestCityName',
			'setqueryBirthPlaceName',
			'requestBirthPlaceName',
			'requestBirthPlaceCityID'
		]),
		showMe(e) {
			// console.log('ready', e)
			this.checkForExistingValues()
		},
		setCategory(selectedName) {
			this.searchCategory = selectedName
			this.setqueryCatName(selectedName)
			let catSelection = this.$store.state.categories
				.filter(category => category[`DescriptionEL`] === selectedName)
			this.requestCat(catSelection[0][`ItemKindID`])
			this.requestCatName(catSelection[0][`DescriptionEL`])
			return catSelection[0][`ItemKindID`]
		},
		clearCategory() {
			this.setqueryCatName(null)
			this.searchCategory = this.$store.state.queryCatName
			this.requestCat(null)
		},
		clearAuthor() {
			// console.log("cleared")
			this.setqueryAuthName(null)
			this.searchAuthor = this.$store.state.queryAuthName
			this.requestAuthID(null)
			this.requestCityID(null)
		},
		clearCity() {
			this.setqueryCityName(null)
			this.searchCity = this.$store.state.queryCityName
			this.requestCityID(null)
		},
		clearBirthPlace() {
			this.setqueryBirthPlaceName(null)
			this.searchBirthPlaceName = this.$store.state.queryBirthPlaceName
			this.requestBirthPlaceCityID(null)
		},

		setAuthor(selectedName) {
			this.searchAuthor = selectedName
			this.setqueryAuthName(selectedName)
			if (selectedName) {
				let selectedNameGR = selectedName.split(" -")[0]
				let selectedNameEN = selectedName.split("- ")[1]
				if (selectedName.startsWith(" - ") || selectedName.endsWith(" - ")) {
					selectedName = selectedName.replace(" - ", "")
				}
				let nameSelection =
					this.$store.state.authors
						.filter(author => author[`CreatorNameEL`] === selectedNameGR || author[`CreatorNameEN`] === selectedNameEN)
				this.requestAuthID(nameSelection[0][`CreatorID`])
				this.requestAuthName(selectedName)
				return nameSelection[0][`CreatorID`]
			}
			return null
		},
		setCity(selectedName) {
			this.searchCity = selectedName
			this.setqueryCityName(selectedName)
			if (selectedName) {
				let selectedNameGR = selectedName.split(" -")[0]
				let selectedNameEN = selectedName.split("- ")[1]
				if (selectedName.startsWith(" - ") || selectedName.endsWith(" - ")) {
					selectedName = selectedName.replace(" - ", "")
				}
				let nameSelection =
					this.$store.state.cities
						.filter(author => author[`CityNameEL`] === selectedNameGR || author[`CityNameEN`] === selectedNameEN)
				this.requestCityID(nameSelection[0][`CityID`])
				this.requestCityName(selectedName)
				return nameSelection[0][`CityID`]
			}
			return null
		},
		setBirthPlaceCity(selectedName) {
			this.searchBirthPlaceName = selectedName
			this.setqueryBirthPlaceName(selectedName)
			if (selectedName) {
				let selectedNameGR = selectedName.split(" -")[0]
				let selectedNameEN = selectedName.split("- ")[1]
				if (selectedName.startsWith(" - ") || selectedName.endsWith(" - ")) {
					selectedName = selectedName.replace(" - ", "")
				}
				let nameSelection =
					this.$store.state.cities
						.filter(author => author[`CityNameEL`] === selectedNameGR || author[`CityNameEN`] === selectedNameEN)
				this.requestBirthPlaceCityID(nameSelection[0][`CityID`])
				this.requestBirthPlaceName(selectedName)
				return nameSelection[0][`CityID`]
			}
			return null
		},
		setDates() {
			this.requestwDateStart(this.w_DateStart)
			this.requestwDateEnd(this.w_DateEnd)
			this.requestpDateStart(this.p_DateStart)
			this.requestpDateEnd(this.p_DateEnd)
		},
		getResults() {
			if (Object.values(this.$route.query).length) {
				this.$router.push({ path: document.location.pathname, query: {} })
			}
			this.resetApiPage()
			this.setDates()
			let requestData = {
				title: this.$store.getters.getReqTitle,
				description: this.$store.getters.getReqDescription,
				writer: this.$store.getters.getReqAuthID,
				authname: '',
				category: this.$store.getters.getReqCat,
				code: this.$store.getters.getReqCode,
				w_date_start: this.$store.getters.getwDateStart,
				w_date_end: this.$store.getters.getwDateEnd,
				p_date_start: this.$store.getters.getpDateStart,
				p_date_end: this.$store.getters.getpDateEnd,
				cityId: this.$store.getters.getReqCityID,
				cityName: '',
				birthPlaceCityId: this.$store.getters.getReqBirthPlaceCityID,
				birthPlaceName: ''
			}
			// for(var k in firstObject) secondObject[k]=firstObject[k];
			const newQuery = {}
			for (let key in requestData) {
				if (requestData[key] !== '' && requestData[key] !== undefined && requestData[key] !== null) {
					newQuery[key] = requestData[key]
				}
			}
			// const condition = getCondition();
			// const additionalCondition = getAdditionalCondition();
			// const person = {
			//   firstName: "Max",
			//   ...(condition && { lastName: "" }),
			//   ...(additionalCondition && { addition: "" }),
			// };

			const hasAuthor = this.searchAuthor !== null
			const hasCategory = this.searchCategory !== null
			const hasCity = this.searchCity !== null
			const hasBirthPlaceName = this.searchBirthPlaceName !== null
			const routeQuery = {
				...newQuery,
				...(hasAuthor && { authName: this.searchAuthor }),
				...(hasCategory && { catNameUrl: this.searchCategory }),
				...(hasCity && { cityName: this.searchCity }),
				...(hasBirthPlaceName && { birthPlaceName: this.searchBirthPlaceName })
			}
			this.$router.push({ path: document.location.pathname, query: routeQuery })
			this.searchData(newQuery)
		},
		getAuthInput(value) {
			this.setqueryAuthName(value)
			// console.log(value)
		},
		getCityInput(value) {
			this.setqueryCityName(value)
		},
		getBirthPlaceInput(value) {
			this.setqueryBirthPlaceName(value)
		},

		clearAll() {
			this.$refs.ac1.search = ''
			this.$refs.ac2.search = ''
			this.resetData()
			this.getResults()
		},
		returnFromBook() {
			this.resetData()
			this.getResults()
		},
		checkForExistingValues() {
			if (Object.values(this.$route.query).length) {
				let requestData = this.$route.query
				// clear object for request
				if (requestData.authName) {
					this.$refs.ac2.search = decodeURI(requestData.authName)
					delete requestData.authName
				}
				if (requestData.catNameUrl) {
					this.$refs.ac1.search = decodeURI(requestData.catNameUrl)
					delete requestData.catNameUrl
				}
				if (requestData.cityName) {
					this.$refs.ac3.search = decodeURI(requestData.cityName)
					delete requestData.cityName
				}

				if (requestData.birthPlaceName) {
					this.$refs.ac4.search = decodeURI(requestData.birthPlaceName)
					delete requestData.birthPlaceName
				}
				this.searchData(requestData)
				this.setForm()
			}
		},
		setForm() {
			const formState = this.$route.query
			this.$store.dispatch('requestTitle', formState.title)
			this.$store.dispatch('requestDescription', formState.description)
			this.$store.dispatch('requestCode', formState.code)
			this.$store.dispatch('requestwDateStart', formState.w_date_start)
			this.$store.dispatch('requestwDateEnd', formState.w_date_end)
			this.$store.dispatch('requestpDateStart', formState.p_date_start)
			this.$store.dispatch('requestpDateEnd', formState.p_date_end)
			this.$store.dispatch('setqueryAuthName', decodeURI(formState.authName))
			this.$store.dispatch('setqueryCityName', decodeURI(formState.cityName))
			this.$store.dispatch('setqueryBirthPlaceName', decodeURI(formState.birthPlaceName))
		}
	},
	created() {
		// this.searchAuthor = this.$store.state.queryAuthName
		// this.searchCategory = this.$store.state.queryCatName
	},
	mounted() {
		this.checkForExistingValues()
		// Check for query onLoad and make new search
	}
}
