<template>
	<div class="map-container">
		<md-dialog :md-active.sync="localShowDialog">
			<md-dialog-actions>
				<div class="map-img">
					<!-- <div
						:style="{ 'background-image': 'url(' +  imgUrl + ')' }"
						class="inner"></div> -->
						<img :src="imgUrl" alt="">
						<!-- <md-button class="md-secondary md-raised" @click="$emit('close')">ΟΚ</md-button> -->
				</div>
			</md-dialog-actions>
		</md-dialog>
	</div>
</template>

<script>
export default {
	name: 'MapModal',
	props: {
		showDialog: {
			type: Boolean,
			required: true,
			default: false
		},
		imgUrl: {
			type: String,
			required: true
		}
	},
	/* data: () => ({
		// showDialog: false
	}) */
	data() {
		return {
			localShowDialog: this.showDialog
		}
	},
	watch: {
		// Watch for changes in the prop and update the local data property
		showDialog(newVal) {
			this.localShowDialog = newVal
		},
		localShowDialog(newVal) {
			this.$emit('update:showDialog', newVal)
		}
	}
}
</script>

<style lang="scss" scoped>

	.md-dialog {
		max-width: 95vw;

	.md-dialog-actions {
		padding: 5px;
		width: 100%;
		max-width: 100%;
		overflow: hidden;
		justify-content: center;
	}

}

	.map-img {
		display: block;
		text-align: right;

		img{
			object-fit: contain;
			// max-width: 80%;
			max-width: 94vw;
			width: 100%;
			overflow: hidden;
			max-height: 70vh;
		}

		button {
			margin-top: 15px;
			align-self: flex-end;
		}
	}
</style>
